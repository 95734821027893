<template>
  <div class="job-container">
    <div class="banner">
      <div class="g-wrap banner-title">
        <div class="banner-zn">培训课程</div>
        <div class="banner-en">TRAINING COURSE</div>
      </div>
    </div>
    <div class="g-section">
      <div class="g-wrap p-20">
        <!-- <el-cascader
          class="mr-10"
          v-model="searchArea"
          :options="optionsArea"
          placeholder="类型"
        ></el-cascader>
        <el-cascader
          class="mr-10"
          v-model="searchMajor"
          :options="optionsMajor"
          placeholder="专业"
        ></el-cascader> -->
        <!-- <el-select v-model="value" class="mr-10" placeholder="规模">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select> -->
        <!-- <el-input
          placeholder="请输入关键字搜索"
          v-model="query.KeyWord"
          class="mr-10 input-with-select"
        >
        </el-input>
        <el-button type="primary" icon="el-icon-search" @click="search"
          >搜索</el-button
        > -->
        <span
          class="search-box"
          @click="handleSearch('')"
          :class="{ select: query.SortName === '' }"
          >最新课程</span
        >
        <span
          class="search-box"
          @click="handleSearch('Enrollments')"
          :class="{ select: query.SortName === 'Enrollments' }"
          >热门课程</span
        >
      </div>
    </div>
    <div class="g-section">
      <div class="g-wrap flex-column mb-20 pb-20">
        <div class="course-list" v-loading="loading">
          <div
            class="course-item"
            v-for="(item, index) in courseList"
            :key="index"
            @click="toDetail(item.Id)"
          >
            <img
              src="@/assets/images/nodata.png"
              v-real-img="item.CoverImageFullPath"
              alt=""
            />
            <div class="course-content">
              <div
                style="display: flex;justify-content: space-between;flex-direction: column;"
              >
                <div>
                  <div class="course-content-title">{{ item.Name }}</div>
                  <div class="course-content-name">
                    {{ item.SignupCount + "人学过" }} | 共{{
                      item.LessonCount
                    }}节
                    <!-- | 时长：2:00:99999 -->
                  </div>
                </div>

                <div class="course-content-bottom">
                  <div class="course-content-name">
                    <span class="nameBox"
                      ><span class="nameBox-txt" v-if="item.OwnerName">{{
                        item.OwnerName
                      }}</span></span
                    >
                  </div>
                  <!-- <div class="bot-money" v-if="item.Price">￥{{ item.Price }}</div> -->
                  <!-- <div class="bot-money" v-else>免费</div> -->
                </div>
              </div>
              <div class="course-right">
                <div class="bot-members">
                  <!-- <span class="color-primary">{{ item.SignupCount }}</span
                  >人学习 -->
                </div>
                <el-button type="primary" size="small">查看详细</el-button>
              </div>
            </div>
          </div>
        </div>
        <el-pagination
          class="mt-18 my-pagination"
          background
          layout="prev, pager, next"
          :page-size="query.PageSize"
          :current-page="query.PageIndex"
          @current-change="currentChange"
          hide-on-single-page
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <AnswerTop />
  </div>
</template>

<script>
import AnswerTop from "@/components/AnswerTop";
import { getPageList } from "@/api/courseseries";
export default {
  components: {
    AnswerTop
  },
  data() {
    return {
      loading: false,
      searchZone: "1",
      searchKeyword: "",
      courseList: [],
      query: {
        KeyWord: "",
        PageSize: 10,
        PageIndex: 1,
        Status: 2,
        SortName: ""
      },
      total: 0
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    handleSearch(value) {
      this.query.SortName = value;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      getPageList(this.query)
        .then(res => {
          this.courseList = res.Result.Data;
          this.total = res.Result.TotalCount;
          this.loading = false;
        })
        .catch(e => {
          console.log(e);
          this.loading = false;

          // this.$message.error('获取数据失败')
        });
    },
    search() {
      this.query.PageIndex = 1;
      this.getDataList();
    },
    currentChange(e) {
      this.query.PageIndex = e;
      this.getDataList();
    },
    toDetail(id) {
      this.$router.push("course/detail?id=" + id);
      // this.$router.push("course/detail?id=" + id);
      // const { href } = this.$router.resolve({
      //   path: "course/detail",
      //   query: {
      //     id: id
      //   }
      // });
      // window.open(href, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
.job-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #ffffff !important;
}
.g-section {
  .p-20 {
    box-sizing: border-box;
    height: 60px;
  }
  .search-box {
    transition: all 0.15s;
    user-select: none;
    cursor: pointer;
    margin-right: 32px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    &:hover {
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #557df7;
      line-height: 20px;
    }
  }
  .select {
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #557df7;
    line-height: 20px;
  }
}
.banner {
  height: 160px;
  width: 100%;
  background-image: url(../../assets/images/banner/information.png);
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
}
.banner-title {
  display: flex;
  color: #ffffff;
  flex-direction: column;
  justify-content: center;
  .banner-zn {
    height: 45px;
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 45px;
  }
  .banner-en {
    height: 23px;
    font-size: 20px;
    font-family: HelveticaNeue;
    color: rgba(255, 255, 255, 0.35);
    line-height: 24px;
  }
}
.condition-section {
  flex-direction: column;
  padding-top: 0px;
}
.course-list {
  width: 100%;
  min-height: 186px;
  box-sizing: border-box;
}
.course-item {
  display: flex;
  width: 100%;
  height: 186px;
  justify-content: space-between;
  border-bottom: 1px solid #eeeeee;
  align-items: center;
  padding: 16px 24px 16px 16px;
  margin-bottom: 16px;
  box-shadow: 0px 0px 16px 0px rgba(51, 51, 51, 0.12);
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.1s;
  &:hover {
    transform: scale(1.015);
    .course-content-title {
      color: #598DF3;
    }
  }
  img {
    height: 100%;
    width: 274px;
    border-radius: 8px;
    flex-shrink: 0;
  }

  .course-content {
    flex-grow: 1;
    padding-left: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 34px;
    height: 100%;
    display: flex;
    justify-content: space-between;

    .course-content-title {
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
      word-break: break-all;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin-top: 8px;
    }

    .course-content-name {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
      .nameBox {
        display: inline-block;
        text-align: center;
        margin: 8px 0;
        height: 25px;
        .nameBox-txt {
          padding: 3px 8px;
          background: #f0f2f4;
          border-radius: 4px;
        }
      }
    }
    .course-content-txt {
      margin-bottom: 8px;
    }

    .course-content-bottom {
      margin-top: 8px;
      display: flex;
      align-items: flex-end;
      .bot-money {
        height: 32px;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #10dcaf;
        line-height: 32px;
        padding-right: 18px;
      }
    }
  }
  .course-right {
    height: 100%;
    padding: 12px 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .bot-members {
      height: 24px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 24px;
      text-align: right;
    }
  }
}

.my-pagination {
  display: flex;
  justify-content: center;
}
</style>
