<template>
  <div v-if="btnFlag" class="go-top" @click="backTop">
    <img :src="img" class="go-img" alt="" />
  </div>
</template>

<script>
export default {
  name: "AnswerTop",
  props: {},
  components: {},
  data() {
    return {
      img: require("@/assets/images/icon_top.png"),
      btnFlag: false,
      scrollTop: 0
    };
  },
  computed: {},
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  watch: {},
  methods: {
    backTop() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },

    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 180) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    }
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
  }
};
</script>

<style scoped lang="scss">
.go-top {
  position: fixed;
  bottom: 100px;
  right: 100px;
  width: 56px;
  height: 56px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  .go-img {
    width: 20px;
    height: 30px;
  }
}
</style>
